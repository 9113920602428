import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'

import { v4 } from 'uuid'

import Form from '../../Form'
import ButtonForm from '../../ButtonForm'

import casesData from '../casesData'

//swiper
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination, EffectFade } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/bundle'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'


function CaseItemHeader({imagePaths}) {

    const { title } = useParams()

    let currentCaseData = casesData.filter(e => e.link === title)
    currentCaseData = currentCaseData[0]

    return (
        <div className="block-background home-background full-screen" loading="lazy">
            {imagePaths.length > 0 ? (
                <Swiper
                    spaceBetween={100}
                    slidesPerView={1}
                    className="swiper-bg"
                    modules={[Autoplay, EffectFade]}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    effect={'fade'}
                >
                    {imagePaths.map((el) => {
                        // console.log(el)

                        return (
                            <SwiperSlide key={v4()}>
                                <div style={{ backgroundImage: `url(${el})`, color: 'red' }} loading="lazy" key={v4()} className="slide-image"></div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            ) : (
                ''
            )}
            <div className="block-wrapper block-wrapper--home">
                <div>
                    <h1 className="home-header">Корпоративная униформа для {currentCaseData.name}</h1>
                    <p>
                        {currentCaseData.headerDescription}
                    </p>

                    <br />

                    <ButtonForm text={'Я тоже так хочу!'}/>
                </div>

                
            </div>
        </div>
    )
}

export default CaseItemHeader
