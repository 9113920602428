import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.scss'

import { FaInstagram } from 'react-icons/fa6'

const Footer = () => {
    return (
        <footer className="footer">
            {/* <div className="footer__links">
                <div>
                    <Link to="/offer">Оферта</Link>
                    <Link to="/contacts">Контакты</Link>
                    <Link to="/return">Возврат товара</Link>
                </div>
                <div>
                    <Link to="/size-chart">Таблица размеров</Link>
                    <Link to="/careers">Карьера</Link>
                    <Link to="/collaboration">Сотрудничество</Link>
                </div>
                <div>
                    <Link to="/gift-certificate">Подарочный сертификат</Link>
                    <Link to="/bonus-program">Бонусная программа</Link>
                    <Link to="/corporate-orders">Корпоративные заказы</Link>
                </div>
            </div> */}

            <div className="footer-second">
                <div className="footer__info">
                    <p>Товарищество с ограниченной ответственностью “Арт Феникс”</p>
                    <br />
                    <p>Район Медеуский, улица Кабанбай батыра, дом 78/49, н.п. 4</p>
                    <p>Почтовый индекс 050010, Алматы</p>
                    <p>Телефон: +77019526066</p>
                    <p>Email: marketing@aigulkassymova.com</p>
                </div>
                <div className="footer__social">
                    {/* <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                        <FaInstagram />
                    </a> */}
                
                        <Link to="/">Шоурум AIGUL KASSYMOVA</Link>
                        <Link to="/">Ателье AIGUL KASSYMOVA</Link>
                    
                </div>
            </div>
        </footer>
    )
}

export default Footer
