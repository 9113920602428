import React from 'react'
import './Accordion.scss'
import { useState } from 'react'
import AccordionItem from './AccordionItem'

function Accordion({ faqList, className }) {
    const [openID, setID] = useState(null)

    return (
        <ul className={`accordion ${className}`}>
            {faqList.map((faqItem, id) => {
                return <AccordionItem faqItem={faqItem} isOpen={id === openID} key={id} onClick={() => (id === openID ? setID(null) : setID(id))} />
            })}
        </ul>
    )
}

export default Accordion
