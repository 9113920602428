import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ScrollToTopOnPageChange = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleScrollToTop = () => {
      window.scrollTo(0, 0);
    };
    handleScrollToTop();

  }, [navigate]);

  return null;
};

export default ScrollToTopOnPageChange;
