export default [
    {
        name: 'Mama Mia',
        link: 'mama-mia',
        description: 'Mamma Mia group – это не просто про еду, это про семью и про любовь!',
        headerDescription: 'Lorem ipsum dolor sit AmetLorem ipsum dolor sit AmetLorem ipsum dolor sit AmetLorem ipsum dolor sit AmetLorem ipsum dolor sit Amet',
        backgroundColor: '#004F32',
        color: '#eeeeee',
    },
    {
        name: 'Mary Kay',
        link: 'mary-kay',
        description: 'Lorem ipsum dolor sit Amet',
        headerDescription: '',
        backgroundColor: '#F881A3',
        color: '#030202',
    },
    {
        name: 'HR Capital',
        link: 'hr-capital',
        description: 'Lorem ipsum dolor sit Amet',
        headerDescription: '',
        backgroundColor: '#e49e48',
        color: '#3D3D3D',
    },
    {
        name: 'MFitness',
        link: 'm-fitness',
        description: 'Lorem ipsum dolor sit Amet',
        headerDescription: '',
        backgroundColor: '#1C2645',
        color: '#E52145',
    },
]
