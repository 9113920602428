import React from 'react'

import { useContext, useEffect, useState } from 'react'
import PopupContext from '../context/popupContext'

import Form from './Form'

import './ButtonForm.scss'

function ButtonForm({text}) {
    const { currentPopup, setCurrentPopup } = useContext(PopupContext)
    const popupContent = <Form />

    return (
        <button
            onClick={(evt) => {
                evt.preventDefault()

                setCurrentPopup({ content: popupContent, isActive: true })
            }}
        >
            {text ? text: 'Свяжитесь со мной!'}
        </button>
    )
}

export default ButtonForm
