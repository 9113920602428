import React from 'react'

//Components
import casesData from './cases/casesData'
import CaseItemMamaMia from './cases/CaseItemMamaMia'
import CaseItemMaryKay from './cases/CaseItemMaryKay'
import CaseItemHrCapital from './cases/CaseItemHrCapital'
import CaseItemMFitness from './cases/CaseItemMFitness'

import { useParams } from 'react-router-dom'

function CaseItem() {
    const { title } = useParams()

    let currentComponent
    // let currentCaseData = casesData.filter(e => e.link === title)
    // console.log(...currentCaseData)


    switch (title) {
        case 'mama-mia':
            currentComponent = <CaseItemMamaMia />
            break
        case 'mary-kay':
            currentComponent = <CaseItemMaryKay />
            break

        case 'hr-capital':
            currentComponent = <CaseItemHrCapital />
            break

        case 'm-fitness':
            currentComponent = <CaseItemMFitness />
            break
    }

    return <>{currentComponent}</>
}

export default CaseItem
