import React from 'react'

import Form from './Form'
import './FormBlock.scss'

function FormBlock() {
    return (
        <div className="block-background full-screen form-background" loading="lazy">


            <div className="block-wrapper block-wrapper--form">
                {/* <div>
                    <h1 className="home-header">Поднимем ваши продажи с помощью корпоративной формы</h1>
                    <p>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Earum blanditiis rerum impedit porro eaque reprehenderit autem accusantium,
                        non aliquam? Excepturi distinctio, cumque temporibus dolores accusamus odio quas. Nesciunt, mollitia deserunt!
                    </p>
                </div> */}

                <Form />
            </div>
        </div>
    )
}

export default FormBlock
