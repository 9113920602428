import React, { forwardRef, useRef } from 'react'

import './About.scss'

import logoSimple from '../images/logo--white.webp'

import aigulPhoto from '../images/IMG_8637.jpg'

const About = forwardRef((props, ref) => {
    // Инициализация рефа с useRef
    const refItem = useRef(100);

    // Логгирование текущего значения рефа
    // console.log(refItem.current.offsetHeight);

    return (
        <div className="block-background full-screen block-about" loading="lazy" id="about" ref={ref}>
            <div className="block-wrapper about-container ">
                <div className="about-container__left" ref={refItem}>
                    <h2>Немного о нас:</h2>

                    <div className="about-container__numbers">
                        <div>
                            <h3>25 лет</h3>
                            <p>Более 25 лет на fashion рынке</p>
                        </div>

                        <div>
                            <h3>c 2007</h3>
                            <p>c 2007 года разрабатываем стиль для компаний</p>
                        </div>

                        <div>
                            <h3>120 км</h3>
                            <p>использовано более 120 км тканей</p>
                        </div>

                        <div>
                            <h3>30 000 чел.</h3>
                            <p>одели более 30 000 человек</p>
                        </div>
                    </div>

                    <img src={logoSimple} alt="Логотип компании" className='logo-company'/>
                </div>

                <img src={aigulPhoto} alt="Фото Айгуль Касымовой" className='aigul-photo' style={{height: `${refItem.current.scrollHeight}px`}}/>
            </div>
        </div>
    )
})

export default About


//style={{height: `${refItem.current.scrollHeight}`}}