//Home.js

import React from 'react'

import './Home.scss'

//Components
import Form from './Form'
import HowItWorks from './HowItWorks'
import MainWishes from './MainWishes'
import About from './About'
import FAQ from './FAQ'
import CheapUniform from './CheapUniform'
import FormBlock from './FormBlock'
import ActiveSectionContext from '../context/activeSectionContext'

import { useEffect, useState, useRef, useContext  } from 'react'

import { v4 } from 'uuid'

//swiper
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination, EffectFade } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/bundle'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

function Home() {
    const [imagePaths, setImagePaths] = useState([])

    const howItWorksRef = useRef(null);
    const aboutRef = useRef(null);
    const { setActiveSection } = useContext(ActiveSectionContext);

    useEffect(() => {
        function importAll(r) {
            let images = {}
            r.keys().map((item, index) => {
                images[item.replace('./', '')] = r(item)
            })
            return images
        }
        const images = importAll(require.context('../images/bg-images/', true, /\.(gif|jpe?g|svg|webp|JPE?G)$/))
        const imagePathsTemp = Object.keys(images).map((el) => {
            return images[el]
        })

        setImagePaths(imagePathsTemp)
    }, [])

    useEffect(() => {
        const sections = [
            { ref: howItWorksRef, id: 'how-it-works' },
            { ref: aboutRef, id: 'about' },
        ];
    
        const observer = new IntersectionObserver(
            (entries) => {
                let activeId = null;
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        activeId = entry.target.id;
                    }
                });
                setActiveSection(activeId); // Устанавливаем активную секцию или null, если ничего не видно
            },
            {
                threshold: 0.6,
            }
        );
    
        sections.forEach((section) => {
            if (section.ref.current) {
                observer.observe(section.ref.current);
            }
        });
    
        return () => {
            sections.forEach((section) => {
                if (section.ref.current) {
                    observer.unobserve(section.ref.current);
                }
            });
        };
    }, [setActiveSection]);

    // console.log(setActiveSection)

    return (
        <>
            <div className="block-background home-background full-screen" loading="lazy">
                {imagePaths.length > 0 ? (
                    <Swiper
                        spaceBetween={100}
                        slidesPerView={1}
                        className="swiper-bg"
                        modules={[Autoplay, EffectFade]}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        loop={true}
                        effect={'fade'}
                    >
                        {imagePaths.map((el) => {
                            // console.log(el)

                            return (
                                <SwiperSlide key={v4()}>
                                    <div style={{ backgroundImage: `url(${el})`, color: 'red' }} loading="lazy" key={v4()} className="slide-image"></div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                ) : (
                    ''
                )}
                <div className="block-wrapper block-wrapper--home">
                    <div>
                        <h1 className="home-header">Поднимем ваши продажи с помощью корпоративной формы</h1>
                        <p>
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Earum blanditiis rerum impedit porro eaque reprehenderit autem
                            accusantium, non aliquam? Excepturi distinctio, cumque temporibus dolores accusamus odio quas. Nesciunt, mollitia deserunt!
                        </p>
                    </div>

                    <Form />
                </div>
            </div>

            <MainWishes />
            <HowItWorks ref={howItWorksRef}/>

            <About ref={aboutRef}/>
            <CheapUniform />

            <FAQ />

            <FormBlock />
        </>
    )
}

export default Home
