import React, { useState, useEffect, useContext } from 'react'

import CaseItemHeader from './casesComponents/CaseItemHeader'

import FormBlock from '../FormBlock'

import './CaseItemMamaMia.scss'

import PopupContext from '../../context/popupContext'

//swiper
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination, EffectFade } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/bundle'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

import { v4 } from 'uuid'

function CaseItemMamaMia() {
    const [imagePathsCurrent, setImagePaths] = useState([])
    const [imagePathsSwiper, setimagePathsSwiper] = useState([])

    const { currentPopup, setCurrentPopup } = useContext(PopupContext)

    useEffect(() => {
        function importAll(r) {
            let images = {}
            r.keys().map((item, index) => {
                images[item.replace('./', '')] = r(item)
            })
            return images
        }
        const images = importAll(require.context('../../images/bg-images/', true, /\.(gif|jpe?g|svg|webp|JPE?G)$/))
        const imagePathsTemp = Object.keys(images).map((el) => {
            return images[el]
        })

        const swiper = importAll(require.context('../../images/cases/mamma-mia/', true, /\.(png|gif|jpe?g|svg|webp|JPE?G)$/))
        const swiperPathsTemp = Object.keys(swiper).map((el) => {
            return swiper[el]
        })

        setImagePaths(imagePathsTemp)
        setimagePathsSwiper(swiperPathsTemp)
    }, [])

    

    function setImageHandler(imgUrl){
        const img = <img src={imgUrl} alt="Фотография работ Мамма Мия" />

        setCurrentPopup({ content: img, isActive: true })
    }

    return (
        <>
            <CaseItemHeader imagePaths={imagePathsCurrent} />

            <div className="block-background" loading="lazy">
                <div className="block-wrapper">
                    <div className="case-quote-block">
                        <div className="case-quote-block__left">
                            <h2>Mamma Mia Group – это не просто про еду, это про семью и про любовь!</h2>

                            <div className="case-quote">
                                «Наша Компания представляет собой сеть заведений: Мамма Mia restaurants, Anytime Café и ресторан Dushes. Особый факт заключён в
                                том, что наша Компания является Семейным Делом. Это означает, что всё лучшее и полезное, что мы отдаём своим родным и близким на
                                протяжении более 18 лет - также предназначается для наших сотрудников, для наших гостей и для наших партнёров. Уверенность,
                                стабильность, чувство комфорта и надёжности - это те ценности, на которых базируется наша работа для всех людей, жизненная
                                позиция которых основана бази на положительном отношении к взаимозависимым потребностям и ожиданиям!»
                            </div>

                            <div>
                                <strong>
                                    Исходя из ценностей и потребностей компании мы разработали корпоративный дресс-код. Костюм администратора ресторана
                                    разработан в двух вариантах.
                                </strong>
                            </div>
                        </div>

                        <div className="case-quote-block__right"></div>
                    </div>
                </div>
            </div>

            <div className="block-background block-background--case-color" loading="lazy" style={{ backgroundColor: '#004F32' }}>
                <div className="block-wrapper block-wrapper--quote">
                    <div className="case-colors">
                        <div className="case-colors__color">
                            <h4>Значок</h4>
                            <div className="color-block" style={{ backgroundColor: '#000' }}></div>
                        </div>
                        <div className="case-colors__color">
                            <h4>Цвет пиджаков и жилетов</h4>
                            <div className="color-block" style={{ backgroundColor: '#000' }}></div>
                        </div>
                        <div className="case-colors__color">
                            <h4>Цвет брюк</h4>
                            <div className="color-block" style={{ backgroundColor: '#000' }}></div>
                        </div>
                        <div className="case-colors__color">
                            <h4>Цвет блузок</h4>
                            <div className="color-block" style={{ backgroundColor: '#000' }}></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="block-background" loading="lazy">
                <div className="block-wrapper block-wrapper--quote">
                    <div className="case-quote-block">
                        <div className="case-quote-block__left">
                            <h2>Комментарии от Айгуль Касымовой о разработке</h2>

                            <div className="case-quote">
                                «Наша Компания представляет собой сеть заведений: Мамма Mia restaurants, Anytime Café и ресторан Dushes. Особый факт заключён в
                                том, что наша Компания является Семейным Делом. Это означает, что всё лучшее и полезное, что мы отдаём своим родным и близким на
                                протяжении более 18 лет - также предназначается для наших сотрудников, для наших гостей и для наших партнёров. Уверенность,
                                стабильность, чувство комфорта и надёжности - это те ценности, на которых базируется наша работа для всех людей, жизненная
                                позиция которых основана бази на положительном отношении к взаимозависимым потребностям и ожиданиям!»
                            </div>

                            <div>
                                <strong>
                                    Исходя из ценностей и потребностей компании мы разработали корпоративный дресс-код. Костюм администратора ресторана
                                    разработан в двух вариантах.
                                </strong>
                            </div>
                        </div>

                        <div className="case-quote-block__right">
                            {imagePathsSwiper.length > 0 ? (
                                <Swiper
                                    spaceBetween={100}
                                    slidesPerView={'auto'}
                                    className="swiper-case"
                                    modules={[Autoplay, EffectFade, Pagination]}
                                    autoplay={{
                                        delay: 30000,
                                        disableOnInteraction: false,
                                    }}
                                    loop={true}
                                    // effect={'fade'}

                                    centeredSlides={true}
                                    pagination={{
                                        enabled: true,
                                    }}
                                >
                                    {imagePathsSwiper.map((el) => {
                                        // console.log(el)

                                        return (
                                            <SwiperSlide key={v4()}>
                                                <div
                                                    style={{ backgroundImage: `url(${el})`, color: 'red' }}
                                                    loading="lazy"
                                                    key={v4()}
                                                    className="slide-image"
                                                    onClick={(evt) => {
                                                        evt.preventDefault()

                                                        setImageHandler(el)
                                                    }}
                                                ></div>
                                            </SwiperSlide>
                                        )
                                    })}
                                </Swiper>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <FormBlock />
        </>
    )
}

export default CaseItemMamaMia
