import React from 'react'

import './FAQ.scss'

import { useEffect, useState } from 'react'

//swiper
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination, EffectFade } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/bundle'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

import { v4 } from 'uuid'

import Accordion from './Accordion'

import ButtonForm from './ButtonForm'

function FAQ() {
    const [imagePathsFAQ, setImagePathsFAQ] = useState([])

    const questions = [
        { q: 'А точно получится как на картинке?', a: 'Да, конечно!' },
        {
            q: 'А как быстро вы сделаете?',
            a: 'За первые две недели проведём анализ вашей компании, клиентов, составим аватар и приступим к созданию концепта формы',
        },
        {
            q: 'Сначала мы хотим КП и время на подумать',
            a: 'Предоставим индивидуальное КП с просчетом разработки концептов, с просчетом пошива, стоимость на 1 сотрудника',
        },
        { q: 'Хочу чтобы внешний вид подходил под санитарные нормы', a: 'Подстроимся под любые санитарные нормы, запросы и стандарты!' },
        {
            q: 'А мои сотрудники будут носить форму?',
            a: 'Конечно! Мы сделаем такую форму, в которой будет комфортно, красиво и донесём ценность новой униформы до ваших сотрудников. Кроме того, готовая форма закрывает вопрос сотрудника “Что надеть?” и экономит его бюджет на одежду',
        },
    ]

    useEffect(() => {
        function importAll(r) {
            let images = {}
            r.keys().map((item, index) => {
                images[item.replace('./', '')] = r(item)
            })
            return images
        }
        const images = importAll(require.context('../images/HIW-slider/', true, /\.(gif|jpe?g|svg|webp|JPG?G)$/))
        const imagePathsFAQTemp = Object.keys(images).map((el) => {
            return images[el]
        })

        // console.log(imagePathsFAQTemp)
        setImagePathsFAQ(imagePathsFAQTemp)
    }, [])

    return (
        <div className="block-background" loading="lazy">
            <div className="block-wrapper faq-container">
                <div className="faq-container__left">
                    {' '}
                    {imagePathsFAQ.length > 0 ? (
                        <Swiper
                            spaceBetween={100}
                            slidesPerView={1}
                            className="swiper-FAQ"
                            modules={[Autoplay, EffectFade]}
                            autoplay={{
                                delay: 30000,
                                disableOnInteraction: false,
                            }}
                            loop={true}
                            effect={'fade'}
                        >
                            {imagePathsFAQ.map((el) => {
                                // console.log(el)

                                return (
                                    <SwiperSlide key={v4()}>
                                        <div
                                            style={{ backgroundImage: `url(${el})`, color: 'red' }}
                                            loading="lazy"
                                            key={v4()}
                                            className="swiper-slide-FAQ"
                                        ></div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    ) : (
                        ''
                    )}
                </div>

                <div className="faq-container__right">
                    <div>
                        <h2>Отвечаем на ваши вопросы</h2>
                        <p>
                            Возможно, у вас остались вопросы? Самые часто задаваемые мы собрали ниже, но если вашего вопроса там нет, жмите на кнопку "У меня еще вопрос!", оставляйте контакт и мы с вами свяжемся.
                        </p>
                        <br />

                        <Accordion faqList={questions} />
                    </div>

                    <ButtonForm text={'У меня еще вопрос!'}/>
                </div>
            </div>
        </div>
    )
}

export default FAQ
