import React, { useRef } from 'react'

function AccordionItem({ faqItem, onClick, isOpen }) {
    const itemRef = useRef(null)

    return (
        <li className="accordion-item">
            <button className={`accordion-header ${isOpen? 'active' : ''}`} onClick={(evt) => {
                    evt.preventDefault()
                    onClick()


                }}>
                {faqItem.q}
                <div className={`accordion-arrow ${isOpen ? 'active' : ''}`} />
            </button>
            <div className="accordion-collapse" style={isOpen ? { height: itemRef.current.scrollHeight } : { height: '0px' }}>
                <div className="accordion-body" ref={itemRef}>
                    {faqItem.a}
                </div>
            </div>
        </li>
    )
}

export default AccordionItem
