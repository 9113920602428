import React from 'react'
import './CheapUniform.scss'

import ButtonForm from './ButtonForm'

function CheapUniform() {
    const uniformComparison = [
        {
            fastForm:
                'Нужно дозаказать форму для нового сотрудника, а коллекция закончилась - корпоративную форму, разработанную для вас, можно производить снова и снова',
            ourForm: 'Всегда доступна, так как разрабатывается индивидуально и может быть произведена по запросу',
        },
        {
            fastForm: 'Неизносостойкая - учтем условия работы и необходимую износостойкость, подберем ткани',
            ourForm: 'Устойчивая к износу, так как изготавливается из материалов, подобранных под конкретные условия работы',
        },
        {
            fastForm: 'Сотрудникам в форме жарко, некомфортно - учтем сезонность и условия работы каждой должности',
            ourForm: 'Комфортная и подходящая под любые условия труда благодаря использованию дышащих и терморегулирующих тканей',
        },
        {
            fastForm: 'Не садится по фигуре - разработаем универсальные модели, которые хорошо смотрятся на любой фигуре',
            ourForm: 'Идеально сидит благодаря индивидуальной подгонке под особенности каждой фигуры',
        },
        {
            fastForm: 'Не можем найти готовую форму подходящего размера - изготовим под любой нестандартный размер',
            ourForm: 'Подходит всем, так как производится на заказ, с учетом любых нестандартных размеров',
        },
    ]

    return (
        <div className="block-background" loading="lazy">
            <div className="block-wrapper cheap-uniform-container">
                <div>
                    <h2>Почему вам не подойдет готовая униформа?</h2>
                    <p>
                        Давайте разберёмся, чем же плоха готовая форма. Сравнивая, мы будем опираться на опыт наших клиентов, которые попробовали готовую форму
                        за вас!
                    </p>
                    <br />
                    <ButtonForm text={'Хочу работающую форму!'} />
                </div>

                <div className="uniform-comparison-table">
                    <div className="header">Наша работающая униформа</div>
                    <div className="header">Готовая униформа</div>

                    {uniformComparison.map((item, index) => (
                        <React.Fragment key={index}>
                            <div className="content">{item.ourForm}</div>
                            <div className="content">{item.fastForm}</div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default CheapUniform
