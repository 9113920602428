import React from 'react'

import { useState } from 'react'

import './Form.scss'

function Form() {
    const [nameState, setNameState] = useState()
    const [phoneState, setPhoneState] = useState()

    function onSubmitHandler() {
        console.log(nameState, phoneState)
    }

    return (
        <form className="form-main">
            <h3>Расскажем всё подробнее!</h3>

            <div className="form-main__input-list">
                <input
                    type="text"
                    placeholder="Как вас зовут?"
                    onChange={(evt) => {
                        setNameState(evt.target.value)
                    }}
                />
                <input
                    type="number"
                    placeholder="Ваш номер телефона?"
                    onChange={(evt) => {
                        setPhoneState(evt.target.value)
                    }}
                />

                <p className="form-main__info">Отправляя эту форму, вы соглашаетесь с нашей политикой конфиденциальности</p>
            </div>

            <button
                onClick={(evt) => {
                    evt.preventDefault()

                    onSubmitHandler()
                }}
            >
                Отправить
            </button>
        </form>
    )
}

export default Form
