//App.js

import logo from './logo.svg'
import './App.scss'

// React
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useState } from 'react'

// Components
import MainLayout from './layouts/MainLayout'
import Home from './components/Home'
import Contacts from './components/Contacts'
import About from './components/About'
import Cases from './components/Cases'
import CaseItem from './components/CaseItem'
import ScrollToTopOnPageChange from './components/ScrollToTopOnPageChange'
import { ActiveSectionProvider } from './context/activeSectionContext'

// Context
import PopupContext from './context/popupContext'

function App() {
    const [currentPopup, setCurrentPopup] = useState({ content: '', isActive: false })

    return (
        <BrowserRouter>
            <ScrollToTopOnPageChange />

            <PopupContext.Provider value={{ currentPopup, setCurrentPopup }}>
                <ActiveSectionProvider>
                    <div className="App">
                        <Routes>
                            <Route path="/" element={<MainLayout />}>
                                <Route index={true} element={<Home />} />
                                <Route path="/contacts" element={<Contacts />} />
                                <Route path="/about" element={<About />} />

                                <Route path="/cases-list" element={<Cases />} />
                                <Route path="/cases-list/:title" element={<CaseItem />} />
                            </Route>
                        </Routes>
                    </div>
                </ActiveSectionProvider>
            </PopupContext.Provider>
        </BrowserRouter>
    )
}

export default App
