import React from 'react'

import { useEffect, useState, forwardRef  } from 'react'

import ButtonForm from './ButtonForm'

import Accordion from './Accordion'

//swiper
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination, EffectFade } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/bundle'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

import { v4 } from 'uuid'

import './HowItWorks.scss'

const HowItWorks = forwardRef((props, ref) => {
    const [imagePathsHIW, setImagePathsHIW] = useState([])

    useEffect(() => {
        function importAll(r) {
            let images = {}
            r.keys().map((item, index) => {
                images[item.replace('./', '')] = r(item)
            })
            return images
        }
        const images = importAll(require.context('../images/HIW-slider/', true, /\.(gif|jpe?g|svg|webp|JPG?G)$/))
        const imagePathsHIWTemp = Object.keys(images).map((el) => {
            return images[el]
        })

        // console.log(imagePathsHIWTemp)
        setImagePathsHIW(imagePathsHIWTemp)
    }, [])

    const faqList = [
        {q: '1. Проходим тест и получаем КП', a: 'Вы проходите тест и получаете коммерческое предложение, в котором уже учтены ваши ответы и подсчитана стоимость'}, 
        {q: '2. Углубляемся в детали', a: 'Мы получаем результаты вашего теста и далее совместно углубляемся в детали вашей компании - разбираем характер вашей компании и создаём её аватар'},
        {q: '3. Что думают ваши покупатели?', a: 'Анализируем ваших покупателей и учитываем их взгляд на вас'},
        {q: '4. Финишная прямая', a: 'Собираем для вас брендбук и гайд по стилю для ваших сотрудников'},
        {q: '5. Всё готово!', a: 'Производим для вас форму или даём всю техническую документацию, чтобы вы могли произвести её там, где хотите'},
    ]

    return (
        <div className="block-background" loading="lazy" id="how-it-works" ref={ref}>
            <div className="block-wrapper HIW-container">
                <div>
                    {imagePathsHIW.length > 0 ? (
                        <Swiper
                            spaceBetween={100}
                            slidesPerView={1}
                            className="swiper-HIW"
                            modules={[Autoplay, EffectFade]}
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                            }}
                            loop={true}
                            effect={'fade'}
                        >
                            {imagePathsHIW.map((el) => {
                                // console.log(el)

                                return (
                                    <SwiperSlide key={v4()}>
                                        <div
                                            style={{ backgroundImage: `url(${el})`, color: 'red' }}
                                            loading="lazy"
                                            key={v4()}
                                            className="swiper-slide-HIW"
                                        ></div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    ) : (
                        ''
                    )}
                </div>

                <div>
                    <div>
                        <h2>Как это будет?</h2>

                        <p>
                            Мы подходим к созданию униформы с разных сторон. Учитываем маркетинг (свой и ваш), видение вас, как собственника, видение ваших
                            сотрудников и, конечно же, возможности ваши и наши.
                        </p>
                    </div>
                    <br />
                    

                    <div>
                        <Accordion faqList={faqList} />
                    </div>

                    <br />
                    <ButtonForm text="Хочу пройти тест и получить КП!" />
                </div>
            </div>
        </div>
    )
})

export default HowItWorks
