import React from 'react'
import './Contacts.scss' // Убедитесь, что стили подключены

function Contacts() {
    return (
        <div className="block-background" loading="lazy" id="how-it-works">
            <div className="block-wrapper contacts-container">
                <h2>КОНТАКТЫ</h2>
                <p>
                    Если у вас есть вопросы по заказу или вам необходим совет по подбору моделей, мы вам поможем. Связаться с нами вы можете удобным способом,
                    используя один из следующих вариантов.
                </p>

                <div className="contacts-grid">
                    <div className="contact-item">
                        <div>
                            <h3>Связь</h3>
                            <p>
                                <a href="tel:+77019526066">+7 701 952 6066</a>
                            </p>
                        </div>
                        <div>
                            <p>Общие вопросы:</p>
                            <p>
                                <a href="mailto:aigulkassymova@gmail.com">aigulkassymova@gmail.com</a>
                            </p>
                        </div>
                        <div>
                            <p>Сотрудничество, реклама:</p>
                            <p>
                                <a href="mailto:marketing@aigulkassymova.com">marketing@aigulkassymova.com</a>
                            </p>
                        </div>
                    </div>

                    <div className="contact-item">
                        <h3>Социальные сети</h3>
                        <p>
                            <a href="https://instagram.com/aigulkassymova_almaty" target="_blank" rel="noopener noreferrer">
                                @aigulkassymova_almaty
                            </a>
                        </p>
                        <p>
                            <a href="https://instagram.com/aigulkassymova_atelier" target="_blank" rel="noopener noreferrer">
                                @aigulkassymova_atelier
                            </a>
                        </p>
                    </div>

                    <div className="contact-item">
                        <h3>Адрес</h3>
                        <p>Шоурум AIGUL KASSYMOVA</p>
                        <p>ул. Кабанбай батыра, 78, уг. ул. Пушкина</p>
                    </div>

                    <div className="contact-item">
                        <h3>Часы работы</h3>
                        <p>Пн-Вс: с 10:00 до 20:00 (без выходных)</p>
                    </div>
                </div>

                {/* Место для карты можно добавить здесь, если потребуется */}
            </div>
        </div>
    )
}

export default Contacts
