import Menu from '../components/Menu'
import Footer from '../components/Footer'
import Popup from '../components/Popup'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'
import PopupContext from '../context/popupContext'

import { Outlet, useLocation } from 'react-router-dom'
import { useContext, useState, useEffect } from 'react'

import { GiHamburgerMenu } from 'react-icons/gi'
import { IoCloseOutline } from 'react-icons/io5'

import '../components/Menu.scss'

function MainLayout() {
    const { currentPopup, setCurrentPopup } = useContext(PopupContext)

    const handleBackdropClick = () => {
        setCurrentPopup({ content: currentPopup.content, isActive: false })
        setTimeout(() => {
            setCurrentPopup({ content: '', isActive: false })
        }, 300)
    }

    const popupContent = <Menu />



    return (
        <>
            <Popup />

            {isMobile && (
                <div
                    className="open-menu-button"
                    onClick={(evt) => {
                        if (!currentPopup.isActive) {
                            setCurrentPopup({ content: popupContent, isActive: true })
                        } else {
                            handleBackdropClick()
                        }
                    }}
                >
                    {currentPopup.isActive ? <IoCloseOutline /> : <GiHamburgerMenu />}
                </div>
            )}

            <div className="container">
                {isBrowser && <Menu />}


                <div className="block">
                    
                        <Outlet />
                    
                </div>
                <div className="block">
                    <div className="block-wrapper">
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    )
}

export default MainLayout
