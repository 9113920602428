import React, { useState, useEffect } from 'react'

import casesData from './cases/casesData'
import CaseItemMamaMia from './cases/CaseItemMamaMia'
import Form from './Form'
import FormBlock from './FormBlock'

import ButtonForm from './ButtonForm'

import { Link } from 'react-router-dom'
import './Cases.scss'

//swiper
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination, EffectFade } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/bundle'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

import { v4 } from 'uuid'

function Cases() {
    const [imagePaths, setImagePaths] = useState([])

    useEffect(() => {
        function importAll(r) {
            let images = {}
            r.keys().map((item, index) => {
                images[item.replace('./', '')] = r(item)
            })
            return images
        }
        const images = importAll(require.context('../images/bg-images/', true, /\.(gif|jpe?g|svg|webp|JPE?G)$/))
        const imagePathsTemp = Object.keys(images).map((el) => {
            return images[el]
        })

        setImagePaths(imagePathsTemp)
    }, [])

    return (
        <>
            <div className="block-background full-screen" loading="lazy" id="how-it-works">
                <div className="block-wrapper block--cases-list">
                    <div className="case-list-header">
                        <div className="case-list-header__info">
                            <div>
                                <h1>Как это было у наших клиентов?</h1>
                                <p>
                                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Laudantium saepe assumenda repudiandae quas unde a ipsam tempore
                                    earum beatae eum ullam eaque, illo rem, aspernatur velit officia cumque, ab hic!
                                </p>
                            </div>
                            <br />
                            <ButtonForm />
                        </div>

                        <div className="case-list-header__slider-container">
                            {imagePaths.length > 0 ? (
                                <Swiper
                                    spaceBetween={100}
                                    slidesPerView={1}
                                    className="swiper-case-list-header"
                                    modules={[Autoplay, EffectFade]}
                                    autoplay={{
                                        delay: 30000,
                                        disableOnInteraction: false,
                                    }}
                                    loop={true}
                                    effect={'fade'}
                                >
                                    {imagePaths.map((el) => {
                                        // console.log(el)

                                        return (
                                            <SwiperSlide key={v4()}>
                                                <div
                                                    style={{ backgroundImage: `url(${el})`, color: 'red' }}
                                                    loading="lazy"
                                                    key={v4()}
                                                    className="swiper-slide-case-header"
                                                ></div>
                                            </SwiperSlide>
                                        )
                                    })}
                                </Swiper>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>

                    <br />
                    <div className='cases-list-container'>
                        <h2 >Список кейсов:</h2>
                        <div className="cases-list">
                            {casesData.map((el, index) => {
                                return (
                                    <div
                                        className="case-list__item"
                                        key={index}
                                        onMouseEnter={(e) => {
                                            e.currentTarget.style.backgroundColor = el.backgroundColor
                                            e.currentTarget.style.color = el.color
                                            e.currentTarget.classList.add('onHover')
                                        }}
                                        onMouseLeave={(e) => {
                                            e.currentTarget.style.backgroundColor = '#eeeeee'
                                            e.currentTarget.style.color = '' // Если нужно сбросить цвет текста
                                            e.currentTarget.classList.remove('onHover')
                                        }}
                                    >
                                        <div>
                                            <h3>{el.name}</h3>
                                            <p>{el.description}</p>
                                        </div>

                                        <Link
                                            to={el.link}
                                            end
                                            onMouseEnter={(e) => {
                                                e.currentTarget.style.setProperty('--before-color', `${el.color}`)
                                            }}
                                            onMouseLeave={(e) => {
                                                e.currentTarget.style.setProperty('--before-color', `transparent`)
                                            }}
                                        >
                                            Открыть кейс
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>

            <FormBlock />
        </>
    )
}

export default Cases
