import React from 'react'

import './MainWishes.scss'
import { Link } from 'react-router-dom'

//swiper
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination, EffectFade } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/bundle'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

import { v4 } from 'uuid'

function MainWishes() {
    const problems = [
        'Сотрудники одеты вразнобой',
        'Нынешняя форма выглядит непрезентабельно, несерьезно',
        'Сейчас непонятно из какого отдела сотрудник и на какой должности',
        'Внешний вид не проходит по санитарным нормам',
        'Не знаю как мои сотрудники должны выглядеть и как исправить их внешний вид',
    ]

    return (
        <div className="block-background" loading="lazy">
            <div className="block-wrapper main-wishes-container">
                <div className="main-wishes-container__text">
                    <div>
                        {' '}
                        <h2>Зачем вашей компании Униформа?</h2>
                        <Swiper
                            slidesPerView={3}
                            // spaceBetween={1000}
                            modules={[Autoplay, EffectFade]}
                            // autoplay={{
                            //     enable: false,
                            //     delay: 7000,
                            //     disableOnInteraction: false,
                            // }}
                            loop={true}
                            effect={'fade'}
                            className="swiper-text"
                        >
                            {problems.map((el) => {
                                return (
                                    <SwiperSlide key={v4()} className="swiper-slide-text ">
                                        <div className="quote">
                                            «{el}»<span className="author">, - один из наших клиентов до того, как мы сделали ему форму</span>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                    <p>
                        Знакомо? Готовы поспорить, что вы сталкиваетесь именно с этими проблемами и мы точно знаем как их решить. Любая из вышеперечисленных проблем косвенно или прямо влияет на ваши продажи. Это доказывает не только теория, но и практика наших клиентов, о которой вы можете почитать в <Link to="/" className='text'>кейсах</Link>
                    </p>

                    <div>
                        <h3>Так зачем вам униформа:</h3>
                        <ul>
                            <li>Униформа показывает вашему клиенту кто вы</li>
                            <li>Качественная униформа ***</li>
                            <li>Наша униформа ***</li>
                            <li>Форма </li>
                        </ul>
                    </div>
                </div>
                <div className="main-wishes-container__video">
                    <video controls poster="/assets/video/cover-video.jpg" className="video-block">
                        <source src="/assets/video/sample-video.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                    <div className="quote">
                        "Трали вали lorem ipsum" <span className="author">, - Айгуль Касымова</span>
                    </div>

                    <div> В этом видео Айгуль лично расскажет о том, что такое униформа и зачем она нужна вашей компании.</div>
                </div>
            </div>
        </div>
    )
}

export default MainWishes
