//Menu.js

import { NavLink } from 'react-router-dom'
import './Menu.scss'
import ActiveSectionContext from '../context/activeSectionContext'

import { useContext, useEffect, useState, useRef } from 'react'
import PopupContext from '../context/popupContext'
import Form from './Form'
import { GiHamburgerMenu } from 'react-icons/gi'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'

import logoSimple from '../images/logo.webp'

import { HashLink } from 'react-router-hash-link'

import ButtonForm from './ButtonForm'

function Menu() {
    const { currentPopup, setCurrentPopup } = useContext(PopupContext)
    const popupContent = <Form />

    const { activeSection } = useContext(ActiveSectionContext)

    const itemRef = useRef(80)

    const handleBackdropClick = () => {
        setCurrentPopup({ content: currentPopup.content, isActive: false })
        setTimeout(() => {
            setCurrentPopup({ content: '', isActive: false })
        }, 300)
    }

    const [isSticky, setIsSticky] = useState(false)

    // console.log(activeSection)

    // useEffect(() => {
    //     const handleScroll = () => {
    //         if (window.innerWidth >= 768) {
    //             if (window.scrollY > 0) {
    //                 setIsSticky(true)
    //             } else {
    //                 setIsSticky(false)
    //             }
    //         } else {
    //             setIsSticky(false)
    //         }
    //     }

    //     window.addEventListener('scroll', handleScroll)
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll)
    //     }
    // }, [])

    // Обновите активную секцию на основе видимости
    useEffect(() => {
        const handleScroll = () => {
            // Этот обработчик будет синхронизировать состояние activeSection из контекста или пропсов
        }
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    // console.log( itemRef.current.scrollHeight )
    // console.log(activeSection)

    return (
        <>
            {/* <div className="menu-fake" style={{height: itemRef.current.scrollHeight}}></div> */}

            <div className="block block-nav" ref={itemRef}>
                <div className="block-wrapper">
                    <nav >
                        <NavLink
                            className={({ isActive }) => (isActive ? 'activeLink nav-image' : 'link nav-image')}
                            to="/"
                            end
                            onClick={(evt) => {
                                handleBackdropClick()
                            }}
                        >
                            <img src={logoSimple} alt="Company logo" className="menu__company-logo" />
                        </NavLink>
                        <NavLink
                            className={({ isActive }) => (isActive ? 'activeLink' : 'link')}
                            to="/"
                            end
                            onClick={(evt) => {
                                handleBackdropClick()
                            }}
                        >
                            Главная
                        </NavLink>

                        <HashLink
                            // className={({ isActive }) => (isActive ? 'activeLink' : 'link')}

                            className={activeSection === 'how-it-works' ? 'activeLink link' : 'link'}
                            to="/#how-it-works"
                            // end
                            onClick={(evt) => {
                                handleBackdropClick()
                            }}
                        >
                            Что мы делаем?
                        </HashLink>

                        <HashLink
                            className={activeSection === 'about' ? 'activeLink link' : 'link'}
                            to="/#about"
                            // end
                            onClick={(evt) => {
                                handleBackdropClick()
                            }}
                        >
                            О нас
                        </HashLink>

                        <NavLink
                            className={({ isActive }) => (isActive ? 'activeLink' : 'link')}
                            to="/cases-list"
                            end
                            onClick={(evt) => {
                                handleBackdropClick()
                            }}
                        >
                            Кейсы
                        </NavLink>

                        <NavLink
                            className={({ isActive }) => (isActive ? 'activeLink' : 'link')}
                            to="/contacts"
                            end
                            onClick={(evt) => {
                                handleBackdropClick()
                            }}
                        >
                            Контакты
                        </NavLink>

                        <ButtonForm />
                    </nav>
                </div>
            </div>
        </>
    )
}

export default Menu
